/*------------------------------------------------------------
footer
------------------------------------------------------------*/

.c {
    &-footer {}
    &-footer__gray {
        padding: 51px 20px 99px;
        background-color: #F7F8F8;
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
    }
    &-footer__title {
        font-size: 3.3rem;
        color: #0092D8;
    }
    &-footer__text {
        margin: 19px 0 54px;
        font-size: 1.6rem;
    }
    &-footer__phone {
        width: 100%;
        max-width: 325.54px;
        @media only screen and (max-width: 767px) {
            max-width: 290.54px;
        }
    }
    &-footer__time {
        margin-left: -26px;
        font-size: 1.8rem;
        @media only screen and (max-width: 767px) {
            margin-left: 2px;
        }
    }
    &-footer__mail {
        margin-left: 10px;
        width: 100%;
        margin-top: 42px;
        max-width: 335.63px;
        @media only screen and (max-width: 767px) {
            max-width: 300px;
        }
    }
    &-footer__blue {
        background-color: #0092D8;
        position: relative;
        color: white;
        padding: 134px 20px 207px;
        @media only screen and (max-width: 767px) {
            padding: 100px 20px 150px;
        }
        @media only screen and (max-width: 516px) {
            padding: 90px 20px 100px;
        }
    }
    &-footer__container {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        max-width: 1022px;
        margin: 0 auto;
        padding-right: 28px;
        @media only screen and (max-width: 1024px) {
            padding-right: 0;
        }
        @media only screen and (max-width: 767px) {
            flex-wrap: wrap;
        }
    }
    &-footer__left {
        @media only screen and (max-width: 767px) {
            width: 100%;
        }
    }
    &-footer__logo {
        width: 306.69px;
        @media only screen and (max-width: 767px) {
            width: 250px;
        }
    }
    &-footer__address {
        margin-top: 26px;
        line-height: 1.45;
        span {
            font-size: 1.6rem;
        }
    }
    &-footer__contact {
        max-width: 278px;
        margin-top: 39px;
        font-size: 1.2rem;
        span {
            font-size: 2.4rem;
        }
        p {
            line-height: 1.25;
            margin-top: 3px;
        }
        a {
            border-radius: 3px;
            display: flex;
            height: 23.68px;
            width: 102.9px;
            font-size: 1.3rem;
            background-color: white;
            color: #1A5292;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
        }
    }
    &-footer__right {
        height: 332.77px;
        margin-top: 16px;
        width: 54%;
        @media only screen and (max-width: 767px) {
            width: 100%;
            margin-top: 30px;
        }
    }
    &-footer__up {
        border: 1px solid;
        width: 46.65px;
        height: 46.65px;
        border-radius: 50%;
        position: absolute;
        top: 15px;
        right: 27px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
            width: 17.6px;
        }
    }
    &-footer__bottom {
        color: #DCDDDD;
        font-size: 1.2rem;
        background-color: #3F3B3A;
    }
    &-footer__white {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        max-width: 1259px;
        margin: 0 auto;
        height: 100px;
        -webkit-box-align: center;
        align-items: center;
        padding: 0 20px;
        @media only screen and (max-width: 516px) {
            flex-wrap: wrap;
        }
    }
    &-footer__copy {
        @media only screen and (max-width: 516px) {
            width: 100%;
            margin-top: 13px;
        }
    }
    &-footer__meta {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        max-width: 254px;
        width: 100%;
        margin-right: 79px;
        @media only screen and (max-width: 1024px) {
            margin-right: 0;
        }
        @media only screen and (max-width: 767px) {
            max-width: 220px;
        }
        @media only screen and (max-width: 516px) {
            margin-bottom: 13px;
        }
    }
    &-footer__link {}
}

.c-footer__up:hover img {
    transition: all .3s ease-in-out;
    margin-bottom: 8px;
}