/* layout
------------------------------------------------------------*/

body {
    font-family: Noto Sans JP, sans-serif;
    font-size: 1.4rem;
    line-height: 1.75;
}

//-------------------------------------------------------
main {}

section {}

//-------------------------------------------------------
.l-container {}

//-------------------------------------------------------
.c-mainvisual {}