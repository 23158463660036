@charset "UTF-8";
/*------------------------------------------------------------
	Reset
------------------------------------------------------------*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
nav, section,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block;
}

html {
  font-size: 62.5%;
}

body {
  -webkit-text-size-adjust: 100%;
}

body, table,
input, textarea, select, option,
h1, h2, h3, h4, h5, h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

table,
input, textarea, select, option {
  line-height: 1.1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

img {
  vertical-align: top;
}

a,
a img {
  transition: 0.3s ease-in-out;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover,
a:hover img {
  opacity: 0.80;
  filter: alpha(opacity=80);
  -ms-filter: "alpha(opacity=80)";
}

img {
  max-width: 100%;
}

.sp-only {
  display: none;
}

@media only screen and (max-width: 767px) {
  .pc-only {
    display: none;
  }
  .sp-only {
    display: block;
  }
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Layout .l-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/* Header
------------------------------------------------------------*/
/* Main menu
------------------------------------------------------------*/
.c-header {
  position: relative;
}

.c-header__blue {
  padding: 10px 20px;
  background-color: #0092D8;
}

.c-header__container {
  max-width: 1216.76px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-header__logo {
  width: 173px;
  margin-right: 20px;
}

.c-header__menu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: 805.17px;
  color: white;
}

@media only screen and (max-width: 1024px) {
  .c-header__menu {
    display: none;
  }
}

.c-header__phone {
  margin-left: -10px;
  width: 173.7px;
}

.c-header__bottom {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  max-width: 1148px;
  margin: 0 auto;
  -webkit-box-align: center;
  align-items: center;
  flex-wrap: wrap;
}

.c-header__item {
  height: 100px;
  -webkit-box-pack: center;
  justify-content: center;
  width: 26%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .c-header__item {
    width: 50% !important;
  }
}

@media only screen and (max-width: 550px) {
  .c-header__item {
    width: 100% !important;
    margin-bottom: 40px;
    height: auto;
  }
  .c-header__item:first-child {
    margin-top: 40px;
  }
}

.c-header__item:nth-child(2) {
  width: 25%;
  position: relative;
}

.c-header__item:nth-child(2)::after {
  position: absolute;
  content: "";
  background-color: #9FA0A0;
  width: 1px;
  height: 97.54px;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 1024px) {
  .c-header__item:nth-child(2)::after {
    display: none;
  }
}

.c-header__item:nth-child(2)::before {
  position: absolute;
  content: "";
  background-color: #9FA0A0;
  width: 1px;
  height: 97.54px;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 1024px) {
  .c-header__item:nth-child(2)::before {
    display: none;
  }
}

.c-header__item:nth-child(3) {
  width: 20.5%;
  flex-direction: column;
  align-items: center;
  position: relative;
}

@media only screen and (max-width: 1100px) {
  .c-header__item:nth-child(3) {
    width: 22.5%;
  }
}

.c-header__item:nth-child(3)::before {
  position: absolute;
  content: "";
  background-color: #9FA0A0;
  width: 1px;
  height: 97.54px;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 1024px) {
  .c-header__item:nth-child(3)::before {
    display: none;
  }
}

.c-header__text {
  width: 80.87px;
}

.c-header__text.blue {
  width: 82px;
}

.c-header__text.green {
  width: 186.15px;
}

.c-header__content {
  font-size: 1.2rem;
  align-items: center;
  display: -webkit-box;
  display: flex;
  line-height: 1.74;
}

.c-header__content span:first-child {
  margin-right: 19px;
  margin-left: 15px;
}

.c-header__staff {
  max-width: 238.95px;
}

.c-header__social {
  position: absolute;
  bottom: -221px;
  right: 17px;
  z-index: 2;
}

.c-header__circle {
  width: 100.76px;
  margin-bottom: 23px;
}

@media only screen and (max-width: 1024px) {
  .c-header__circle {
    width: 70.76px;
  }
}

.c-header__meta {
  z-index: 1001;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  display: -webkit-box;
  display: flex;
  position: absolute;
  top: 12px;
  right: 2.6%;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.p-burger {
  display: flex;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 1001;
  position: relative;
  flex-direction: column;
  align-items: center;
}

.p-burger .p-burger__container {
  justify-content: space-around;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  height: 21px;
  position: relative;
  cursor: pointer;
  margin-bottom: 3px;
}

.p-burger .p-burger__container {
  width: 32.3px;
  display: -webkit-box;
  display: flex;
}

.p-burger .c-line {
  width: 32.3px;
  height: 2px;
  background-color: white;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.p-burger__text {
  font-weight: bolder;
  color: #231916;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.p-burger.is-active {
  top: 30px;
  position: absolute;
  right: 20px;
}

.p-burger.is-active .c-line {
  position: absolute;
}

.p-burger.is-active .c-header__text {
  display: none;
}

.p-burger .c-line.is-active {
  background-color: white;
}

.p-burger.is-active .c-line:nth-child(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.p-burger.is-active .c-line:nth-child(2) {
  display: none;
}

.p-burger.is-active .c-line:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.p-burger__text.is-active {
  color: black;
}

.c-menu {
  padding: 5px 20px;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #0092D8;
  top: 0;
  left: 0;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: 0.4s -webkit-transform ease-in-out;
  transition: 0.4s -webkit-transform ease-in-out;
  transition: 0.4s transform ease-in-out;
  transition: 0.4s transform ease-in-out, 0.4s -webkit-transform ease-in-out;
}

.c-menu.is-open {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.c-header .l-container {
  height: 578px;
  padding-top: 32px;
}

.c-menu__logo {
  z-index: 5;
  position: absolute;
  top: 32px;
  left: 20px;
}

.c-menu ul {
  position: absolute;
  width: 70%;
  top: 17%;
  left: 43%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.c-menu li {
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: white;
}

body.is-hidden {
  touch-action: none;
  -ms-touch-action: none;
  overflow-y: hidden;
}

.c-header__meta {
  display: none;
}

@media only screen and (max-width: 767px) {
  .c-header__meta {
    display: block;
  }
}

@media only screen and (max-width: 516px) {
  .p-burger__text {
    font-size: 1.1rem;
  }
}

/*------------------------------------------------------------
footer
------------------------------------------------------------*/
.c-footer__gray {
  padding: 51px 20px 99px;
  background-color: #F7F8F8;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}

.c-footer__title {
  font-size: 3.3rem;
  color: #0092D8;
}

.c-footer__text {
  margin: 19px 0 54px;
  font-size: 1.6rem;
}

.c-footer__phone {
  width: 100%;
  max-width: 325.54px;
}

@media only screen and (max-width: 767px) {
  .c-footer__phone {
    max-width: 290.54px;
  }
}

.c-footer__time {
  margin-left: -26px;
  font-size: 1.8rem;
}

@media only screen and (max-width: 767px) {
  .c-footer__time {
    margin-left: 2px;
  }
}

.c-footer__mail {
  margin-left: 10px;
  width: 100%;
  margin-top: 42px;
  max-width: 335.63px;
}

@media only screen and (max-width: 767px) {
  .c-footer__mail {
    max-width: 300px;
  }
}

.c-footer__blue {
  background-color: #0092D8;
  position: relative;
  color: white;
  padding: 134px 20px 207px;
}

@media only screen and (max-width: 767px) {
  .c-footer__blue {
    padding: 100px 20px 150px;
  }
}

@media only screen and (max-width: 516px) {
  .c-footer__blue {
    padding: 90px 20px 100px;
  }
}

.c-footer__container {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 1022px;
  margin: 0 auto;
  padding-right: 28px;
}

@media only screen and (max-width: 1024px) {
  .c-footer__container {
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .c-footer__container {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 767px) {
  .c-footer__left {
    width: 100%;
  }
}

.c-footer__logo {
  width: 306.69px;
}

@media only screen and (max-width: 767px) {
  .c-footer__logo {
    width: 250px;
  }
}

.c-footer__address {
  margin-top: 26px;
  line-height: 1.45;
}

.c-footer__address span {
  font-size: 1.6rem;
}

.c-footer__contact {
  max-width: 278px;
  margin-top: 39px;
  font-size: 1.2rem;
}

.c-footer__contact span {
  font-size: 2.4rem;
}

.c-footer__contact p {
  line-height: 1.25;
  margin-top: 3px;
}

.c-footer__contact a {
  border-radius: 3px;
  display: flex;
  height: 23.68px;
  width: 102.9px;
  font-size: 1.3rem;
  background-color: white;
  color: #1A5292;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.c-footer__right {
  height: 332.77px;
  margin-top: 16px;
  width: 54%;
}

@media only screen and (max-width: 767px) {
  .c-footer__right {
    width: 100%;
    margin-top: 30px;
  }
}

.c-footer__up {
  border: 1px solid;
  width: 46.65px;
  height: 46.65px;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  right: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.c-footer__up img {
  width: 17.6px;
}

.c-footer__bottom {
  color: #DCDDDD;
  font-size: 1.2rem;
  background-color: #3F3B3A;
}

.c-footer__white {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 1259px;
  margin: 0 auto;
  height: 100px;
  -webkit-box-align: center;
  align-items: center;
  padding: 0 20px;
}

@media only screen and (max-width: 516px) {
  .c-footer__white {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 516px) {
  .c-footer__copy {
    width: 100%;
    margin-top: 13px;
  }
}

.c-footer__meta {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 254px;
  width: 100%;
  margin-right: 79px;
}

@media only screen and (max-width: 1024px) {
  .c-footer__meta {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .c-footer__meta {
    max-width: 220px;
  }
}

@media only screen and (max-width: 516px) {
  .c-footer__meta {
    margin-bottom: 13px;
  }
}

.c-footer__up:hover img {
  transition: all .3s ease-in-out;
  margin-bottom: 8px;
}

/*------------------------------------------------------------
sidebar
------------------------------------------------------------*/
/* Breadcrumb
------------------------------------------------------------*/
/* layout
------------------------------------------------------------*/
body {
  font-family: Noto Sans JP, sans-serif;
  font-size: 1.4rem;
  line-height: 1.75;
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Component .c-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/*------------------------------------------------------------
btn
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
title
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
icon
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
form
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
text
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
navi
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
img
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
list
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
table
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
line
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
video
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
video
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
other
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Project .p-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/
.p-top1 {
  background-color: #00A5E3;
  padding: 25px 20px;
}

.p-top1__blue {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  color: white;
  margin: 0 auto;
  max-width: 968px;
  -webkit-box-align: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .p-top1__blue {
    flex-wrap: wrap;
  }
}

.p-top1__left {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 24.5%;
}

@media only screen and (max-width: 767px) {
  .p-top1__left {
    width: 100%;
    justify-content: center;
  }
}

.p-top1__icon {
  width: 27.76px;
  margin-right: 8px;
}

.p-top1__info {
  font-size: 1.5rem;
}

.p-top1__right {
  width: 80%;
  border-left: 1px solid;
  padding: 20px 25px;
}

@media only screen and (max-width: 767px) {
  .p-top1__right {
    width: 100%;
    border-left: none;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 516px) {
  .p-top1__right {
    padding: 20px 0;
  }
}

.p-top1__blog {
  display: flex;
  font-size: 1.3rem;
  margin-bottom: 7.5px;
}

.p-top1__blog:last-child {
  margin-bottom: 0;
}

.p-top1__category {
  color: #FFF100;
}

.p-top2 {
  background-color: #F9F9F9;
  padding: 16px 20px;
  border-bottom: 1px solid #9FA0A0;
}

@media only screen and (max-width: 767px) {
  .p-top2 {
    position: relative;
    padding: 16px 20px 80px;
  }
}

.p-top2__gray {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 968px;
  -webkit-box-align: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .p-top2__gray {
    flex-wrap: wrap;
  }
}

.p-top2__left {
  width: 24.5%;
  position: relative;
  min-width: 211px;
}

@media only screen and (max-width: 767px) {
  .p-top2__left {
    width: 100%;
    justify-content: center;
    position: unset;
  }
}

.p-top2__blue {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: #00A5E3;
  font-size: 1.5rem;
}

@media only screen and (max-width: 767px) {
  .p-top2__blue {
    justify-content: center;
  }
}

.p-top2__icon {
  width: 35.11px;
  margin-right: -1px;
}

.p-top2__link {
  bottom: -71px;
  position: absolute;
  display: block;
  width: 140.35px;
  left: 31px;
}

@media only screen and (max-width: 767px) {
  .p-top2__link {
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.p-top2__right {
  width: 80%;
  border-left: 1px solid #00A5E3;
  padding: 13px 25px;
}

@media only screen and (max-width: 767px) {
  .p-top2__right {
    width: 100%;
    border-left: none;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 516px) {
  .p-top2__right {
    padding: 13px 0;
  }
}

.p-top2__blog {
  display: flex;
  font-size: 1.2rem;
  margin-bottom: 1.5px;
}

@media only screen and (max-width: 516px) {
  .p-top2__blog {
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
}

.p-top2__arrow {
  width: 6.74px;
  margin-top: 5px;
  margin-right: 12px;
}

.p-top2__date {
  color: #00A5E3;
  font-size: 1.3rem;
  margin-right: 14px;
}

@media only screen and (max-width: 516px) {
  .p-top2__title {
    width: 100%;
  }
}

.p-top3 {
  max-width: 1040px;
  margin: 63px auto;
  padding: 0 20px;
}

.p-top3__blue {
  margin-left: 12px;
  font-size: 2rem;
  color: #00A5E3;
}

.p-top3__list {
  margin-top: 27px;
  display: flex;
  margin-left: calc( var(--px1) * -1);
  flex-wrap: wrap;
}

.p-top3__item {
  width: calc(100% / 4 - var(--px1));
  margin-left: var(--px1);
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .p-top3__item {
    width: calc(100% / 2 - var(--px1));
  }
}

.p-top3__title {
  margin-top: 9px;
  font-size: 1.6rem;
  padding: 0 5px;
  line-height: 1.6;
}

.p-top4 {
  padding: 31px 20px 54px;
  background-color: #F6F6F6;
  margin-top: 147px;
}

@media only screen and (max-width: 767px) {
  .p-top4 {
    margin-top: 100px;
  }
}

@media only screen and (max-width: 516px) {
  .p-top4 {
    margin-top: 80px;
  }
}

.p-top4__container {
  max-width: 1000px;
  margin: 0 auto;
}

.p-top4__content {
  background-color: white;
  height: 487.85px;
  margin-top: 34px;
}

.p-top5 {
  padding: 0 20px;
}

@media only screen and (max-width: 516px) {
  .p-top5 {
    padding: 0 15px;
  }
}

.p-top5__slide {
  max-width: 1130px;
  margin: 99px auto 114px;
}

.p-top5__slide .slick-list {
  width: 97%;
  margin: 0 auto;
}

@media only screen and (max-width: 1024px) {
  .p-top5__slide .slick-list {
    width: 93%;
  }
}

@media only screen and (max-width: 516px) {
  .p-top5__slide .slick-list {
    width: 90%;
  }
}

.p-top5__list .slick-slide {
  margin: 0 25px;
}

@media only screen and (max-width: 1024px) {
  .p-top5__list .slick-slide {
    margin: 0 10px;
  }
}

.p-top5__list .slick-next {
  right: 0;
}

@media only screen and (max-width: 767px) {
  .p-top5__list .slick-next {
    right: -5px;
  }
}

.p-top5__list .slick-prev {
  z-index: 2;
  left: 0;
}

.p-top5__list .slick-prev:before,
.p-top5__list .slick-next:before {
  background-size: cover;
  background-image: url(../img/slide/slide_left.svg);
  width: 14px;
  height: 26.25px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  content: '';
}

.p-top5__list .slick-next:before {
  background-image: url(../img/slide/slide_right.svg);
}

:root {
  --px1: 38px;
}

@media only screen and (max-width: 1024px) {
  :root {
    --px1: 20px;
  }
}

@media only screen and (max-width: 767px) {
  :root {
    --px1: 30px;
  }
}

@media only screen and (max-width: 516px) {
  :root {
    --px1: 20px;
  }
}

.c-slide {
  position: relative;
}

@media only screen and (max-width: 516px) {
  .c-slide {
    padding: 0 20px;
  }
}

.c-slide__list.sp {
  display: none;
}

@media only screen and (max-width: 767px) {
  .c-slide__list.sp {
    display: block;
  }
  .c-slide__list.pc {
    display: none;
  }
}

.c-slide__left {
  grid-gap: 3rem;
  display: grid;
  grid-template-columns: repeat(2, 3fr);
  grid-template-rows: repeat(2, 3fr);
  top: 50%;
  left: 12%;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 40px;
  border-radius: 7px;
  width: 28.8%;
  max-width: 381.34px;
}

@media only screen and (max-width: 1024px) {
  .c-slide__left {
    grid-gap: 1rem;
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .c-slide__left {
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
    background-color: #00a6e3c4;
    max-width: 300px;
    margin: 20px auto 0;
    width: 100%;
  }
}

.c-slide__travel {
  border-radius: 7px;
  overflow: hidden;
}

.c-slide__video {
  position: absolute;
  width: 30.4%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
  right: 13.2%;
  max-width: 402px;
}

@media only screen and (max-width: 767px) {
  .c-slide__video {
    position: relative;
    top: unset;
    right: unset;
    transform: unset;
    max-width: 400px;
    margin: 40px auto;
    width: 100%;
  }
}

.c-slide__link:first-child {
  margin-bottom: 30px;
}

/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/
.p-access__main {
  max-width: 1140px;
  padding: 0 20px;
  margin: 130px auto 152px;
}

.p-access__list {
  display: flex;
}

.p-access__left {
  padding-left: 5px;
  width: 62%;
  margin-right: 52px;
}

.p-access__right {
  margin-top: 5px;
}

.p-access__part {
  margin-bottom: 42px;
}

.p-access__content {
  padding-left: 5px;
}

.p-access__title {
  background-color: #0092D8;
  font-size: 1.8rem;
  padding: 3px 14px;
  color: white;
  display: inline-block;
  margin-bottom: 3px;
}

.p-access__address {
  font-size: 1.5rem;
}

.p-access__tel {
  font-size: 2.525rem;
  margin: 4px 0 1px;
}

.p-access__time {
  letter-spacing: 1.5px;
  font-size: 1.8rem;
}

.p-access__link {
  border: 1px solid;
  width: 253.18px;
  height: 43.67px;
  font-size: 1.8rem;
  color: #0092D8;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 50px;
}

.p-access__link span {
  margin-left: 60px;
  margin-right: 53px;
}

.p-access__arrow {
  width: 11px;
  display: flex;
}

.p-access__meta {
  margin-top: 112px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.p-access__item {
  width: 32.5%;
}

.c-subpage__title {
  display: flex;
  align-items: center;
  background-color: #F2F2F3;
  height: 94.76px;
}

.c-subpage__title h2 {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  font-size: 2.64rem;
}

.c-subpage__link {
  max-width: 1100px;
  margin: 3px auto;
  font-size: 1.2rem;
}

.p-app__main {
  max-width: 1140px;
  padding: 0 20px;
  margin: 126px auto 204px;
}

.p-app__part {
  margin-bottom: 155px;
}

.p-app__title {
  font-size: 1.8rem;
  background-color: #00A5E3;
  color: white;
  padding: 3px 11px;
  margin-bottom: 32px;
}

.p-app__title.second {
  margin-bottom: 20px;
}

.p-app__box {
  border: 1px solid;
  padding: 6px 8px;
  display: inline-block;
  margin-left: 3px;
}

.p-app__content {
  margin-top: 8px;
  margin-left: 11px;
}

.p-app__blue {
  font-size: 1.6rem;
  color: #00A5E3;
}

.p-app__note {
  margin-top: 16px;
  font-size: 1.2rem;
}

.p-app__file {
  margin: 22px 13px 67px;
  line-height: 2;
}

.p-app__text {
  margin-bottom: 45px;
}

.p-app__text p {
  margin: 11px 0 11px;
  font-size: 1.6rem;
  line-height: 2.1;
}

.p-app__text p span {
  color: #00A5E3;
}

.p-app__pdf {
  margin-top: -9px;
  margin-left: 11px;
}

.p-app__pdf p {
  margin-top: 12px;
  line-height: 1.2;
}

.p-app__pdf a {
  color: #00A5E3;
}

.p-contact__main {
  max-width: 1140px;
  padding: 0 20px;
  margin: 150px auto 204px;
}

.p-contact__part {
  margin-bottom: 136px;
}

.p-contact__phone {
  max-width: 331.12px;
  margin-top: 39px;
  margin-left: 4px;
}

.p-contact__time {
  margin-left: 68px;
  letter-spacing: 3px;
}

.p-flow__main {
  max-width: 1140px;
  padding: 0 20px;
  margin: 127px auto 170px;
}

.p-flow__item {
  padding: 11px 0;
  display: -webkit-box;
  display: flex;
  border-bottom: 1px solid #D9D9DA;
  -webkit-box-align: center;
  align-items: center;
}

.p-flow__item:first-child {
  border-top: 1px solid #D9D9DA;
}

.p-flow__left {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 35.5%;
}

.p-flow__number {
  margin-left: 50px;
  width: 50.58px;
  margin-right: 17px;
}

.p-flow__title {
  font-size: 2rem;
  color: #0092D8;
}

.p-flow__right {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 64%;
}

.p-flow__image {
  margin-right: 21px;
  width: 197.35px;
}

.p-flow__content {
  width: 61%;
}

.p-flow__staff {
  max-width: 777.08px;
  margin: 130px auto 0;
}

.p-site__main {
  max-width: 1140px;
  padding: 0 20px;
  margin: 156px auto 205px;
}

.p-site__list {
  display: flex;
  margin-left: 2px;
}

.p-site__item {
  font-size: 1.6rem;
  width: 24.5%;
}

.p-site__item li {
  margin-bottom: 61.5px;
}

.p-site__item img {
  width: 13.15px;
  margin-top: 10px;
  margin-left: 5px;
}

.p-site__item span {
  border-bottom: 1px solid;
}

.p-site__sub {
  margin-top: 8px;
  margin-left: 33px;
  line-height: 2.15;
}

.p-shop__bg {
  margin: 46px 0 73px;
}

.p-shop__list {
  max-width: 1140px;
  padding: 0 20px;
  margin: 0 auto 190px;
}

.p-shop__item {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-bottom: 1px solid #C9CACA;
}

.p-shop__item:first-child {
  border-top: 1px solid #C9CACA;
}

.p-shop__left {
  padding: 29px 0;
  width: 22.5%;
}

.p-shop__right {
  padding: 29px 44px;
  width: 77.5%;
  position: relative;
  letter-spacing: 1.5px;
}

.p-shop__right::after {
  position: absolute;
  content: "";
  background-color: #C9CACA;
  width: 1px;
  height: calc(100% - 18px);
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.p-qa__main {
  max-width: 1140px;
  margin: 134px auto 166px;
  padding: 0 20px;
}

.p-faq__category {
  font-size: 2rem;
  padding-left: 8px;
  padding-bottom: 7px;
  border-bottom: 1px solid;
  margin-bottom: 63px;
}

.p-faq__part {
  margin-bottom: 83px;
}

.faq1-item {
  margin-bottom: 43px;
}

.faq1-accordion {
  position: relative;
  display: -webkit-box;
  display: flex;
  background-color: #00A5E3;
  width: 100%;
  padding: 0px 77px 0px 15px;
  color: white;
  line-height: 1.55;
}

.accordion1 {
  cursor: pointer;
}

.faq1-icon {
  font-size: 2.4rem;
  text-align: left;
}

.faq1-question {
  min-width: 44px;
}

.faq1-title {
  font-size: 1.8rem;
  margin-top: 5px;
  text-align: left;
}

.accordion1::after {
  background-image: url(../img/arrow/arrow_qa.svg);
  width: 29px;
  height: 11px;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 14px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.panel {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.3s ease-out;
  transition: max-height 0.3s ease-out;
}

.faq1-container {
  padding: 18px 13px 29px;
  display: -webkit-box;
  display: flex;
}

.faq1-answer {
  color: #00A5E3;
  min-width: 45px;
}

.faq1-content {
  margin-top: 9px;
  font-size: 1.4rem;
  line-height: 1.9;
  max-width: 991px;
}

.accordion1.is-active::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.p-privacy__main {
  max-width: 1140px;
  margin: 91px auto 181px;
  padding: 0 20px;
}

.p-privacy__main .p-app__title {
  padding: 1px 14px;
  margin-bottom: 13px;
}

.p-privacy__info {
  margin-left: 5px;
  max-width: 1042px;
  margin-bottom: 52px;
}

.p-privacy__item {
  margin-bottom: 39px;
}

.p-privacy__content {
  margin-left: 5px;
  max-width: 1042px;
}

.p-privacy__content p {
  margin: 25px 0;
}

.p-detail__main {
  max-width: 1140px;
  margin: 181px auto;
  padding: 0 20px;
}

.p-deatil__pickup {
  margin-bottom: 46px;
  padding-bottom: 3px;
  font-size: 2.6rem;
  border-bottom: 1px solid;
}

.p-detail__content {
  margin-left: 4px;
  line-height: 2.4;
}

.p-detail__content img {
  height: auto;
  margin: 24px 0;
}

.p-order__main {
  max-width: 1140px;
  margin: 85px auto 248px;
  padding: 0 20px;
}

.p-order__main .p-app__title {
  font-size: 2rem;
  padding: 1px 10px;
  margin-bottom: 52px;
}

.p-order__content {
  line-height: 1.72;
}

.p-order__content p {
  margin-left: 17px;
  max-width: 1063.12px;
}

.p-order__content img {
  height: auto;
  margin: 24px 8px;
}

.p-voice__main {
  margin-bottom: 72px;
}

.p-voice__slide {
  position: relative;
  margin: 41px 0 114px;
}

.p-voice__text {
  width: 262.61px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.p-voice__meta {
  padding: 0 20px;
  max-width: 1121px;
  margin: 0 auto;
}

.p-voice__list {
  margin-top: 27px;
  display: -webkit-box;
  display: flex;
  margin-left: calc( var(--px2) * -1);
  flex-wrap: wrap;
}

.p-voice__item {
  width: calc(100% / 4 - var(--px2));
  margin-left: var(--px2);
  margin-bottom: 37px;
}

.p-voice__container {
  margin-top: 5px;
  margin-left: 6px;
}

.p-voice__title {
  font-size: 2rem;
}

.p-voice__content {
  margin-top: 7px;
  line-height: 1.55;
}

:root {
  --px2: 50px;
}

.p-news__main {
  max-width: 1140px;
  margin: 148px auto 232px;
  padding: 0 20px;
}

.p-news__list {
  display: flex;
  justify-content: space-between;
  margin-left: 8px;
}

.p-news__left {
  width: 67.5%;
}

.p-news__left .p-top2__blog {
  margin-bottom: 11.5px;
}

.p-news__left .p-top2__arrow {
  width: 8px;
}

.p-news__left .p-top2__title {
  line-height: 1.5;
  font-size: 1.4rem;
}

.p-news__right {
  width: 28.5%;
}

.p-news__blue {
  font-size: 1.8rem;
  padding: 2px 15px;
  color: white;
  background-color: #00A5E3;
}

.p-news__category {
  margin-top: 16px;
}

.p-news__category li {
  padding-left: 16px;
  padding-bottom: 8px;
  font-size: 1.2rem;
  border-bottom: 1px solid #E6E6E6;
  margin-bottom: 4px;
}

.p-news__detail {
  max-width: 1156px;
  margin: 102px auto 232px;
  padding: 0 20px;
}

.p-news__detail .p-news__list {
  margin-left: 8px;
}

.p-news__detail .p-news__right {
  margin-top: 39px;
  width: 27.7%;
  margin-right: 12px;
}

.p-news__date {
  margin-left: 4px;
}

.p-news__title {
  padding-bottom: 3px;
  font-size: 2.6rem;
  border-bottom: 1px solid;
}

.p-news__content {
  margin-top: 46px;
}

.p-news__content p {
  line-height: 2.4;
  margin-left: 4px;
}

.p-news__content img {
  height: auto;
  margin: 45px 0;
}

.p-made__slide {
  position: relative;
  margin-top: 43px;
}

.p-made__title {
  position: absolute;
  top: 28%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 41.6%;
}

.p-made__text {
  position: absolute;
  top: 60%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 45.2%;
}

.p-made__contact {
  position: absolute;
  top: 71%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 18%;
}

.p-made__blue {
  margin: 84px 0 50px;
  font-size: 3.3rem;
  text-align: center;
  color: #0092D8;
}

.p-made__arrow {
  width: 59.77px;
  margin: 0 auto;
}

.p-made__post {
  background-color: #7CC8F0;
  color: white;
  margin-top: 49px;
  text-align: center;
  padding-top: 30px;
  padding: 30px 20px 32px;
}

.p-made__white {
  font-size: 3rem;
  position: relative;
  display: inline-block;
}

.p-made__white::after {
  position: absolute;
  content: "";
  background-color: white;
  width: 3px;
  height: 30.94px;
  top: 55%;
  left: -30px;
  transform: translateY(-50%) rotate(-23deg);
}

.p-made__white::before {
  position: absolute;
  content: "";
  background-color: white;
  width: 3px;
  height: 30.94px;
  top: 55%;
  right: -30px;
  transform: translateY(-50%) rotate(23deg);
}

.p-made__list {
  max-width: 1100px;
  margin: 24px auto;
  grid-gap: 0.5rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.p-made__item {
  position: relative;
}

.p-made__info {
  font-size: 1.8rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.p-made__bottom {
  max-width: 1140px;
  margin: 140px auto 101px;
  padding: 0 20px;
}

.p-made__part .p-voice__meta {
  padding: 0;
  max-width: 1082px;
  margin: 36px auto;
}

.p-made__part .p-top2__link {
  position: relative;
  bottom: unset;
  left: unset;
  margin: 40px auto 0;
}

.p-made__meta {
  max-width: 1020.48px;
  margin: 91px auto 126px;
  display: -webkit-box;
  display: flex;
  justify-content: space-between;
}

.p-made__box {
  width: 31%;
}

.p-made__second {
  margin-top: 17px;
  margin-right: 5px;
}

.p-made__second p {
  max-width: 238.41px;
  margin-left: 56px;
  line-height: 1.6;
  margin-top: 1px;
}

.p-made__second .p-access__link {
  margin-top: 32px;
  margin-left: auto;
}

.p-made__second .p-access__link span {
  margin-left: 68px;
  margin-right: 47px;
}

.p-made__number {
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.p-made__number img {
  width: 43.1px;
  margin-left: 2px;
  margin-right: 10px;
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Utility .u-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
