/* Header
------------------------------------------------------------*/

.c-header {}


/* Main menu
------------------------------------------------------------*/

.c {
    &-header {
        position: relative;
    }
    &-header__blue {
        padding: 10px 20px;
        background-color: #0092D8;
    }
    &-header__container {
        max-width: 1216.76px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &-header__logo {
        width: 173px;
        margin-right: 20px;
    }
    &-header__right {}
    &-header__menu {
        width: 100%;
        display: flex;
        justify-content: space-between;
        max-width: 805.17px;
        color: white;
        @media only screen and (max-width: 1024px) {
            display: none;
        }
    }
    &-header__phone {
        margin-left: -10px;
        width: 173.7px;
    }
    &-header__bottom {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        max-width: 1148px;
        margin: 0 auto;
        -webkit-box-align: center;
        align-items: center;
        flex-wrap: wrap;
    }
    &-header__item {
        height: 100px;
        -webkit-box-pack: center;
        justify-content: center;
        width: 26%;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        @media only screen and (max-width: 1024px) {
            width: 50% !important;
        }
        @media only screen and (max-width: 550px) {
            &:first-child {
                margin-top: 40px;
            }
            width: 100% !important;
            margin-bottom: 40px;
            height: auto;
        }
        &:nth-child(1) {}
        &:nth-child(2) {
            width: 25%;
            position: relative;
            &::after {
                position: absolute;
                content: "";
                background-color: #9FA0A0;
                width: 1px;
                height: 97.54px;
                top: 0;
                left: 0;
                @media only screen and (max-width: 1024px) {
                    display: none;
                }
            }
            &::before {
                position: absolute;
                content: "";
                background-color: #9FA0A0;
                width: 1px;
                height: 97.54px;
                top: 0;
                right: 0;
                @media only screen and (max-width: 1024px) {
                    display: none;
                }
            }
        }
        &:nth-child(3) {
            width: 20.5%;
            flex-direction: column;
            align-items: center;
            position: relative;
            @media only screen and (max-width: 1100px) {
                width: 22.5%;
            }
            &::before {
                position: absolute;
                content: "";
                background-color: #9FA0A0;
                width: 1px;
                height: 97.54px;
                top: 0;
                right: 0;
                @media only screen and (max-width: 1024px) {
                    display: none;
                }
            }
        }
        &:nth-child(4) {}
    }
    &-header__text {
        width: 80.87px;
        &.blue {
            width: 82px;
        }
        &.green {
            width: 186.15px;
        }
    }
    &-header__content {
        font-size: 1.2rem;
        align-items: center;
        display: -webkit-box;
        display: flex;
        line-height: 1.74;
        span:first-child {
            margin-right: 19px;
            margin-left: 15px;
        }
    }
    &-header__staff {
        max-width: 238.95px;
    }
    &-header__social {
        position: absolute;
        bottom: -221px;
        right: 17px;
        z-index: 2;
    }
    &-header__circle {
        width: 100.76px;
        margin-bottom: 23px;
        @media only screen and (max-width: 1024px) {
            width: 70.76px;
        }
    }
}

.c-header__meta {
    z-index: 1001;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    display: -webkit-box;
    display: flex;
    position: absolute;
    top: 12px;
    right: 2.6%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.p-burger {
    display: flex;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    z-index: 1001;
    position: relative;
    flex-direction: column;
    align-items: center;
}

.p-burger .p-burger__container {
    justify-content: space-around;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    height: 21px;
    position: relative;
    cursor: pointer;
    margin-bottom: 3px;
}

.p-burger .p-burger__container {
    width: 32.3px;
    display: -webkit-box;
    display: flex;
}

.p-burger .c-line {
    width: 32.3px;
    height: 2px;
    background-color: white;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.p-burger__text {
    font-weight: bolder;
    color: #231916;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.p-burger.is-active {
    top: 30px;
    position: absolute;
    right: 20px;
}

.p-burger.is-active .c-line {
    position: absolute;
}

.p-burger.is-active .c-header__text {
    display: none;
}

.p-burger .c-line.is-active {
    background-color: white;
}

.p-burger.is-active .c-line:nth-child(1) {
    -webkit-transform: rotate( 45deg);
    transform: rotate( 45deg);
}

.p-burger.is-active .c-line:nth-child(2) {
    display: none;
}

.p-burger.is-active .c-line:nth-child(3) {
    -webkit-transform: rotate( -45deg);
    transform: rotate( -45deg);
}

.p-burger__text.is-active {
    color: black;
}

.c-menu {
    padding: 5px 20px;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: #0092D8;
    top: 0;
    left: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: 0.4s -webkit-transform ease-in-out;
    transition: 0.4s -webkit-transform ease-in-out;
    transition: 0.4s transform ease-in-out;
    transition: 0.4s transform ease-in-out, 0.4s -webkit-transform ease-in-out;
}

.c-menu.is-open {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.c-header .l-container {
    height: 578px;
    padding-top: 32px;
}

.c-menu__logo {
    z-index: 5;
    position: absolute;
    top: 32px;
    left: 20px;
}

.c-menu ul {
    position: absolute;
    width: 70%;
    top: 17%;
    left: 43%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.c-menu li {
    margin-bottom: 20px;
    font-size: 1.8rem;
    color: white;
}

body.is-hidden {
    touch-action: none;
    -ms-touch-action: none;
    overflow-y: hidden;
}

.c-header__meta {
    display: none;
}

@media only screen and (max-width: 767px) {
    .c-header__meta {
        display: block;
    }
}

@media only screen and (max-width: 516px) {
    .p-burger__text {
        font-size: 1.1rem;
    }
}