/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/

.p {
    &-top {}
    &-top1 {
        background-color: #00A5E3;
        padding: 25px 20px;
    }
    &-top1__blue {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        color: white;
        margin: 0 auto;
        max-width: 968px;
        -webkit-box-align: center;
        align-items: center;
        @media only screen and (max-width: 767px) {
            flex-wrap: wrap;
        }
    }
    &-top1__left {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        width: 24.5%;
        @media only screen and (max-width: 767px) {
            width: 100%;
            justify-content: center;
        }
    }
    &-top1__icon {
        width: 27.76px;
        margin-right: 8px;
    }
    &-top1__info {
        font-size: 1.5rem;
    }
    &-top1__right {
        width: 80%;
        border-left: 1px solid;
        padding: 20px 25px;
        @media only screen and (max-width: 767px) {
            width: 100%;
            border-left: none;
            margin-top: 5px;
        }
        @media only screen and (max-width: 516px) {
            padding: 20px 0;
        }
    }
    &-top1__blog {
        display: flex;
        font-size: 1.3rem;
        margin-bottom: 7.5px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &-top1__category {
        color: #FFF100;
    }
    &-top1__title {}
    &-top2 {
        background-color: #F9F9F9;
        padding: 16px 20px;
        border-bottom: 1px solid #9FA0A0;
        @media only screen and (max-width: 767px) {
            position: relative;
            padding: 16px 20px 80px;
        }
    }
    &-top2__gray {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 968px;
        -webkit-box-align: center;
        align-items: center;
        @media only screen and (max-width: 767px) {
            flex-wrap: wrap;
        }
    }
    &-top2__left {
        width: 24.5%;
        position: relative;
        min-width: 211px;
        @media only screen and (max-width: 767px) {
            width: 100%;
            justify-content: center;
            position: unset;
        }
    }
    &-top2__blue {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        color: #00A5E3;
        font-size: 1.5rem;
        @media only screen and (max-width: 767px) {
            justify-content: center;
        }
    }
    &-top2__icon {
        width: 35.11px;
        margin-right: -1px;
    }
    &-top2__info {}
    &-top2__link {
        bottom: -71px;
        position: absolute;
        display: block;
        width: 140.35px;
        left: 31px;
        @media only screen and (max-width: 767px) {
            bottom: 40px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    &-top2__right {
        width: 80%;
        border-left: 1px solid #00A5E3;
        padding: 13px 25px;
        @media only screen and (max-width: 767px) {
            width: 100%;
            border-left: none;
            margin-top: 5px;
        }
        @media only screen and (max-width: 516px) {
            padding: 13px 0;
        }
    }
    &-top2__blog {
        display: flex;
        font-size: 1.2rem;
        margin-bottom: 1.5px;
        @media only screen and (max-width: 516px) {
            flex-wrap: wrap;
            margin-bottom: 15px;
        }
    }
    &-top2__arrow {
        width: 6.74px;
        margin-top: 5px;
        margin-right: 12px;
    }
    &-top2__date {
        color: #00A5E3;
        font-size: 1.3rem;
        margin-right: 14px;
    }
    &-top2__title {
        @media only screen and (max-width: 516px) {
            width: 100%;
        }
    }
    &-top3 {
        max-width: 1040px;
        margin: 63px auto;
        padding: 0 20px;
    }
    &-top3__blue {
        margin-left: 12px;
        font-size: 2rem;
        color: #00A5E3;
    }
    &-top3__list {
        margin-top: 27px;
        display: flex;
        margin-left: calc( var(--px1) * -1);
        flex-wrap: wrap;
    }
    &-top3__item {
        width: calc(100% / 4 - var(--px1));
        margin-left: var(--px1);
        margin-bottom: 10px;
        @media only screen and (max-width: 767px) {
            width: calc(100% / 2 - var(--px1));
        }
    }
    &-top3__image {}
    &-top3__title {
        margin-top: 9px;
        font-size: 1.6rem;
        padding: 0 5px;
        line-height: 1.6;
    }
    &-top4 {
        padding: 31px 20px 54px;
        background-color: #F6F6F6;
        margin-top: 147px;
        @media only screen and (max-width: 767px) {
            margin-top: 100px;
        }
        @media only screen and (max-width: 516px) {
            margin-top: 80px;
        }
    }
    &-top4__container {
        max-width: 1000px;
        margin: 0 auto;
    }
    &-top4__content {
        background-color: white;
        height: 487.85px;
        margin-top: 34px;
    }
    &-top5 {
        padding: 0 20px;
        @media only screen and (max-width: 516px) {
            padding: 0 15px;
        }
    }
    &-top5__slide {
        max-width: 1130px;
        margin: 99px auto 114px;
        .slick-list {
            width: 97%;
            margin: 0 auto;
            @media only screen and (max-width: 1024px) {
                width: 93%;
            }
            @media only screen and (max-width: 516px) {
                width: 90%;
            }
        }
    }
    &-top5__button {}
    &-top5__left {}
    &-top5__right {}
    &-top5__list {
        .slick-slide {
            margin: 0 25px;
            @media only screen and (max-width: 1024px) {
                margin: 0 10px;
            }
        }
    }
    &-top5__item {}
}

.p-top5__list .slick-next {
    right: 0;
    @media only screen and (max-width: 767px) {
        right: -5px;
    }
}

.p-top5__list .slick-prev {
    z-index: 2;
    left: 0;
}

.p-top5__list .slick-prev:before,
.p-top5__list .slick-next:before {
    background-size: cover;
    background-image: url(../img/slide/slide_left.svg);
    width: 14px;
    height: 26.25px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    content: '';
}

.p-top5__list .slick-next:before {
    background-image: url(../img/slide/slide_right.svg);
}

:root {
    --px1: 38px;
    @media only screen and (max-width: 1024px) {
        --px1: 20px;
    }
    @media only screen and (max-width: 767px) {
        --px1: 30px;
    }
    @media only screen and (max-width: 516px) {
        --px1: 20px;
    }
}

.c {
    &-slide {
        position: relative;
        @media only screen and (max-width: 516px) {
            padding: 0 20px;
        }
    }
    &-slide__list {
        &.sp {
            display: none;
        }
        @media only screen and (max-width: 767px) {
            &.sp {
                display: block;
            }
            &.pc {
                display: none;
            }
        }
    }
    &-slide__image {}
    &-slide__left {
        grid-gap: 3rem;
        display: grid;
        grid-template-columns: repeat(2, 3fr);
        grid-template-rows: repeat(2, 3fr);
        top: 50%;
        left: 12%;
        background: rgba(255, 255, 255, 0.5);
        position: absolute;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        padding: 40px;
        border-radius: 7px;
        width: 28.8%;
        max-width: 381.34px;
        @media only screen and (max-width: 1024px) {
            grid-gap: 1rem;
            padding: 20px;
        }
        @media only screen and (max-width: 767px) {
            position: relative;
            top: unset;
            left: unset;
            transform: unset;
            background-color: #00a6e3c4;
            max-width: 300px;
            margin: 20px auto 0;
            width: 100%;
        }
    }
    &-slide__travel {
        border-radius: 7px;
        overflow: hidden;
    }
    &-slide__video {
        position: absolute;
        width: 30.4%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        top: 50%;
        right: 13.2%;
        max-width: 402px;
        @media only screen and (max-width: 767px) {
            position: relative;
            top: unset;
            right: unset;
            transform: unset;
            max-width: 400px;
            margin: 40px auto;
            width: 100%;
        }
    }
    &-slide__link {
        &:first-child {
            margin-bottom: 30px;
        }
    }
}