/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/

.p {
    &-access {}
    &-access__main {
        max-width: 1140px;
        padding: 0 20px;
        margin: 130px auto 152px;
    }
    &-access__list {
        display: flex;
    }
    &-access__left {
        padding-left: 5px;
        width: 62%;
        margin-right: 52px;
    }
    &-access__right {
        margin-top: 5px;
    }
    &-access__part {
        margin-bottom: 42px;
    }
    &-access__content {
        padding-left: 5px;
    }
    &-access__title {
        background-color: #0092D8;
        font-size: 1.8rem;
        padding: 3px 14px;
        color: white;
        display: inline-block;
        margin-bottom: 3px;
    }
    &-access__address {
        font-size: 1.5rem;
    }
    &-access__tel {
        font-size: 2.525rem;
        margin: 4px 0 1px;
    }
    &-access__time {
        letter-spacing: 1.5px;
        font-size: 1.8rem;
    }
    &-access__link {
        border: 1px solid;
        width: 253.18px;
        height: 43.67px;
        font-size: 1.8rem;
        color: #0092D8;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        margin-top: 50px;
        span {
            margin-left: 60px;
            margin-right: 53px;
        }
    }
    &-access__arrow {
        width: 11px;
        display: flex;
    }
    &-access__meta {
        margin-top: 112px;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }
    &-access__item {
        width: 32.5%;
    }
}

.c {
    &-subpage {}
    &-subpage__title {
        display: flex;
        align-items: center;
        background-color: #F2F2F3;
        height: 94.76px;
        h2 {
            width: 100%;
            max-width: 1100px;
            margin: 0 auto;
            font-size: 2.64rem;
        }
    }
    &-subpage__link {
        max-width: 1100px;
        margin: 3px auto;
        font-size: 1.2rem;
    }
}

.p {
    &-app__main {
        max-width: 1140px;
        padding: 0 20px;
        margin: 126px auto 204px;
    }
    &-app__part {
        margin-bottom: 155px;
    }
    &-app__title {
        font-size: 1.8rem;
        background-color: #00A5E3;
        color: white;
        padding: 3px 11px;
        margin-bottom: 32px;
        &.second {
            margin-bottom: 20px;
        }
    }
    &-app__box {
        border: 1px solid;
        padding: 6px 8px;
        display: inline-block;
        margin-left: 3px;
    }
    &-app__content {
        margin-top: 8px;
        margin-left: 11px;
    }
    &-app__blue {
        font-size: 1.6rem;
        color: #00A5E3;
    }
    &-app__note {
        margin-top: 16px;
        font-size: 1.2rem;
    }
    &-app__file {
        margin: 22px 13px 67px;
        line-height: 2;
    }
    &-app__text {
        margin-bottom: 45px;
        p {
            margin: 11px 0 11px;
            font-size: 1.6rem;
            line-height: 2.1;
            span {
                color: #00A5E3;
            }
        }
    }
    &-app__down {}
    &-app__pdf {
        margin-top: -9px;
        margin-left: 11px;
        p {
            margin-top: 12px;
            line-height: 1.2;
        }
        a {
            color: #00A5E3;
        }
    }
}

.p {
    &-contact__main {
        max-width: 1140px;
        padding: 0 20px;
        margin: 150px auto 204px;
    }
    &-contact__part {
        margin-bottom: 136px;
    }
    &-app__title {}
    &-contact__phone {
        max-width: 331.12px;
        margin-top: 39px;
        margin-left: 4px;
    }
    &-contact__time {
        margin-left: 68px;
        letter-spacing: 3px;
    }
    &-contact__text {}
}

.p {
    &-flow__main {
        max-width: 1140px;
        padding: 0 20px;
        margin: 127px auto 170px;
    }
    &-flow__list {}
    &-flow__item {
        padding: 11px 0;
        display: -webkit-box;
        display: flex;
        border-bottom: 1px solid #D9D9DA;
        -webkit-box-align: center;
        align-items: center;
        &:first-child {
            border-top: 1px solid #D9D9DA;
        }
    }
    &-flow__left {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        width: 35.5%;
    }
    &-flow__number {
        margin-left: 50px;
        width: 50.58px;
        margin-right: 17px;
    }
    &-flow__title {
        font-size: 2rem;
        color: #0092D8;
    }
    &-flow__right {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        width: 64%;
    }
    &-flow__image {
        margin-right: 21px;
        width: 197.35px;
    }
    &-flow__content {
        width: 61%;
    }
    &-flow__staff {
        max-width: 777.08px;
        margin: 130px auto 0;
    }
}

.p {
    &-site__main {
        max-width: 1140px;
        padding: 0 20px;
        margin: 156px auto 205px;
    }
    &-site__list {
        display: flex;
        margin-left: 2px;
    }
    &-site__item {
        font-size: 1.6rem;
        width: 24.5%;
        li {
            margin-bottom: 61.5px;
        }
        img {
            width: 13.15px;
            margin-top: 10px;
            margin-left: 5px;
        }
        span {
            border-bottom: 1px solid;
        }
    }
    &-site__sub {
        margin-top: 8px;
        margin-left: 33px;
        line-height: 2.15;
    }
}

.p {
    &-shop__main {}
    &-shop__bg {
        margin: 46px 0 73px;
    }
    &-shop__list {
        max-width: 1140px;
        padding: 0 20px;
        margin: 0 auto 190px;
    }
    &-shop__item {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        border-bottom: 1px solid #C9CACA;
        &:first-child {
            border-top: 1px solid #C9CACA;
        }
    }
    &-shop__left {
        padding: 29px 0;
        width: 22.5%;
    }
    &-shop__right {
        padding: 29px 44px;
        width: 77.5%;
        position: relative;
        letter-spacing: 1.5px;
        &::after {
            position: absolute;
            content: "";
            background-color: #C9CACA;
            width: 1px;
            height: calc(100% - 18px);
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }
}

.p-qa__main {
    max-width: 1140px;
    margin: 134px auto 166px;
    padding: 0 20px;
}

.p-faq__category {
    font-size: 2rem;
    padding-left: 8px;
    padding-bottom: 7px;
    border-bottom: 1px solid;
    margin-bottom: 63px;
}

.p-faq__part {
    margin-bottom: 83px;
}

.faq1-list {}

.faq1-item {
    margin-bottom: 43px;
}

.faq1-accordion {
    position: relative;
    display: -webkit-box;
    display: flex;
    background-color: #00A5E3;
    width: 100%;
    padding: 0px 77px 0px 15px;
    color: white;
    line-height: 1.55;
}

.accordion1 {
    cursor: pointer;
}

.faq1-icon {
    font-size: 2.4rem;
    text-align: left;
}

.faq1-question {
    min-width: 44px;
}

.faq1-title {
    font-size: 1.8rem;
    margin-top: 5px;
    text-align: left;
}

.accordion1::after {
    background-image: url(../img/arrow/arrow_qa.svg);
    width: 29px;
    height: 11px;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    content: "";
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 14px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.panel {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.3s ease-out;
    transition: max-height 0.3s ease-out;
}

.faq1-container {
    padding: 18px 13px 29px;
    display: -webkit-box;
    display: flex;
}

.faq1-answer {
    color: #00A5E3;
    min-width: 45px;
}

.faq1-content {
    margin-top: 9px;
    font-size: 1.4rem;
    line-height: 1.9;
    max-width: 991px;
}

.accordion1.is-active::after {
    -webkit-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
}

.p {
    &-privacy__main {
        max-width: 1140px;
        margin: 91px auto 181px;
        padding: 0 20px;
        .p-app__title {
            padding: 1px 14px;
            margin-bottom: 13px;
        }
    }
    &-privacy__info {
        margin-left: 5px;
        max-width: 1042px;
        margin-bottom: 52px;
    }
    &-privacy__list {}
    &-privacy__item {
        margin-bottom: 39px;
    }
    &-app__title {}
    &-privacy__content {
        margin-left: 5px;
        max-width: 1042px;
        p {
            margin: 25px 0;
        }
    }
}

.p {
    &-detail__main {
        max-width: 1140px;
        margin: 181px auto;
        padding: 0 20px;
    }
    &-deatil__pickup {
        margin-bottom: 46px;
        padding-bottom: 3px;
        font-size: 2.6rem;
        border-bottom: 1px solid;
    }
    &-detail__content {
        margin-left: 4px;
        line-height: 2.4;
        img {
            height: auto;
            margin: 24px 0;
        }
    }
}

.p {
    &-order__main {
        max-width: 1140px;
        margin: 85px auto 248px;
        padding: 0 20px;
        .p-app__title {
            font-size: 2rem;
            padding: 1px 10px;
            margin-bottom: 52px;
        }
    }
    &-order__content {
        line-height: 1.72;
        p {
            margin-left: 17px;
            max-width: 1063.12px;
        }
        img {
            height: auto;
            margin: 24px 8px;
        }
    }
}

.p {
    &-voice__main {
        margin-bottom: 72px;
    }
    &-voice__slide {
        position: relative;
        margin: 41px 0 114px;
    }
    &-voice__travel {}
    &-voice__text {
        width: 262.61px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &-voice__meta {
        padding: 0 20px;
        max-width: 1121px;
        margin: 0 auto;
    }
    &-voice__list {
        margin-top: 27px;
        display: -webkit-box;
        display: flex;
        margin-left: calc( var(--px2) * -1);
        flex-wrap: wrap;
    }
    &-voice__item {
        width: calc(100% / 4 - var(--px2));
        margin-left: var(--px2);
        margin-bottom: 37px;
    }
    &-voice__image {}
    &-voice__container {
        margin-top: 5px;
        margin-left: 6px;
    }
    &-voice__title {
        font-size: 2rem;
    }
    &-voice__content {
        margin-top: 7px;
        line-height: 1.55;
    }
}

:root {
    --px2: 50px;
}

.p {
    &-news__main {
        max-width: 1140px;
        margin: 148px auto 232px;
        padding: 0 20px;
    }
    &-news__list {
        display: flex;
        justify-content: space-between;
        margin-left: 8px;
    }
    &-news__left {
        width: 67.5%;
        .p {
            &-top2__blog {
                margin-bottom: 11.5px;
            }
            &-top2__arrow {
                width: 8px;
            }
            &-top2__date {}
            &-top2__title {
                line-height: 1.5;
                font-size: 1.4rem;
            }
        }
    }
    &-news__right {
        width: 28.5%;
    }
    &-news__blue {
        font-size: 1.8rem;
        padding: 2px 15px;
        color: white;
        background-color: #00A5E3;
    }
    &-news__category {
        margin-top: 16px;
        li {
            padding-left: 16px;
            padding-bottom: 8px;
            font-size: 1.2rem;
            border-bottom: 1px solid #E6E6E6;
            margin-bottom: 4px;
        }
    }
}

.p {
    &-news__detail {
        max-width: 1156px;
        margin: 102px auto 232px;
        padding: 0 20px;
        .p-news__list {
            margin-left: 8px;
        }
        .p-news__right {
            margin-top: 39px;
            width: 27.7%;
            margin-right: 12px;
        }
    }
    &-news__list {}
    &-news__left {}
    &-news__date {
        margin-left: 4px;
    }
    &-news__title {
        padding-bottom: 3px;
        font-size: 2.6rem;
        border-bottom: 1px solid;
    }
    &-news__content {
        margin-top: 46px;
        p {
            line-height: 2.4;
            margin-left: 4px;
        }
        img {
            height: auto;
            margin: 45px 0;
        }
    }
    &-news__right {}
    &-news__blue {}
    &-news__category {}
}

.p {
    &-made__main {}
    &-made__slide {
        position: relative;
        margin-top: 43px;
    }
    &-made__bg {}
    &-made__travel {}
    &-made__title {
        position: absolute;
        top: 28%;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 41.6%;
    }
    &-made__text {
        position: absolute;
        top: 60%;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 45.2%;
    }
    &-made__contact {
        position: absolute;
        top: 71%;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 18%;
    }
    &-made__blue {
        margin: 84px 0 50px;
        font-size: 3.3rem;
        text-align: center;
        color: #0092D8;
    }
    &-made__arrow {
        width: 59.77px;
        margin: 0 auto;
    }
    &-made__post {
        background-color: #7CC8F0;
        color: white;
        margin-top: 49px;
        text-align: center;
        padding-top: 30px;
        padding: 30px 20px 32px;
    }
    &-made__white {
        font-size: 3rem;
        position: relative;
        display: inline-block;
        &::after {
            position: absolute;
            content: "";
            background-color: white;
            width: 3px;
            height: 30.94px;
            top: 55%;
            left: -30px;
            transform: translateY(-50%) rotate(-23deg);
        }
        &::before {
            position: absolute;
            content: "";
            background-color: white;
            width: 3px;
            height: 30.94px;
            top: 55%;
            right: -30px;
            transform: translateY(-50%) rotate(23deg);
        }
    }
    &-made__list {
        max-width: 1100px;
        margin: 24px auto;
        grid-gap: 0.5rem;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }
    &-made__item {
        position: relative;
    }
    &-made__image {}
    &-made__info {
        font-size: 1.8rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
    &-made__bottom {
        max-width: 1140px;
        margin: 140px auto 101px;
        padding: 0 20px;
    }
    &-made__part {
        .p-voice__meta {
            padding: 0;
            max-width: 1082px;
            margin: 36px auto;
        }
        .p-top2__link {
            position: relative;
            bottom: unset;
            left: unset;
            margin: 40px auto 0;
        }
    }
    &-app__title {}
    &-made__meta {
        max-width: 1020.48px;
        margin: 91px auto 126px;
        display: -webkit-box;
        display: flex;
        justify-content: space-between;
    }
    &-made__box {
        width: 31%;
    }
    &-made__first {}
    &-made__second {
        margin-top: 17px;
        margin-right: 5px;
        p {
            max-width: 238.41px;
            margin-left: 56px;
            line-height: 1.6;
            margin-top: 1px;
        }
        .p-access__link {
            margin-top: 32px;
            margin-left: auto;
        }
        .p-access__link span {
            margin-left: 68px;
            margin-right: 47px;
        }
    }
    &-made__number {
        font-size: 2rem;
        display: flex;
        align-items: center;
        img {
            width: 43.1px;
            margin-left: 2px;
            margin-right: 10px;
        }
    }
    &-access__link {}
    &-access__arrow {}
    &-voice__meta {}
    &-voice__list {}
    &-voice__item {}
    &-voice__image {}
    &-voice__container {}
    &-voice__title {}
    &-voice__content {}
}